import axios from 'axios';
import { toast } from 'react-toastify';

console.log(`API URL: ${process.env.REACT_APP_API_URL}`);

// Used for authentication requests. Eg: login, check, etc.
const authApiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 60000,
});

// Used for all other requests. It manages session expiration flow.
const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 60000,
});

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 && error.response?.statusText === "Unauthorized") {
      toast.error("Sua sessão expirou. Faça login novamente.", {
        position: "top-right",
        autoClose: 2500,
        onClose: () => goToSignIn()
      });
      return;
    }
    return Promise.reject(error);
  }
);

function goToSignIn() {
  window.location.href = '/signin';
}

export {
  apiInstance,
  authApiInstance
}