import { useState } from "react";
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate } from 'react-router-dom'
import { apiInstance } from "../services/api_service";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signup = () => {
  const { register, formState: { errors }, handleSubmit } = useForm({
    criteriaMode: "all"
  });

  const [password, setPassword] = useState("");
  const navigate = useNavigate()

  const onSubmit = async (formData) => {
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      password: formData.password,
      password_confirmation: formData.confirmPassword
    };
    const response = await apiInstance.post(
      'authentication/user/create',
      payload, {headers: {'Content-Type': 'application/json'}}
    ).catch((error) => {
      if (error.status === 400) {
        const { data } = error.response;
        let delay = 0;
        for (const key in data) {
          const value = data[key];
          toast.error(<div>{`${key}: ${value[0].toLowerCase()}`}</div>, {
            position: "top-right",
            delay: delay
          });
          delay += 500;
        }
      }
    });

    if (response === undefined) {
      return;
    }
    
    const data = response.data;
    sessionStorage.clear();
    sessionStorage.setItem('access_token', data.token);
    apiInstance.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
    toast.success("Signup successful", {
      position: "top-right",
      autoClose: 1500,
      onClose: () => navigate('/')
    });
    
  }

  return (
    <div>
      <h1>Signup</h1>
      <p></p>
      <form onSubmit={handleSubmit(onSubmit)} className="max-w-md mx-auto">
        <input {...register("firstName", { 
            required: "First Name is required", 
            maxLength: 20 , 
            minLength: 2}
          )} type="text" placeholder="First Name" className="w-full"
        />
        <ErrorMessage
          errors={errors}
          name="firstName"
          render={({ messages }) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              : null;
          }}
        />
        
        <input {...register("lastName", {
            required: "Last Name is required",
            maxLength: 20,
            minLength: 2
          })} type="text" placeholder="Last Name" className="w-full"
        />
        <ErrorMessage
          errors={errors}
          name="lastName"
          render={({ messages }) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              : null;
          }}
        />
        
        <input {...register("email", {
          required: "Email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Invalid email address"
          }
        })} type="text" placeholder="Email" className="w-full"/>
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ messages }) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              : null;
          }}
        />
        
        <input {...register("password", {
          required: "Password is required",
          minLength: {
            value: 8,
            message: "Password must have at least 8 characters"
          },
          onChange: (e) => setPassword(e.target.value)
        })} type="password" placeholder="Password" className="w-full"/>
        <ErrorMessage
          errors={errors}
          name="password"
          render={({ messages }) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              : null;
          }} 
        />

        <input {...register("confirmPassword", {
          required: "Confirm Password is required",
          validate: function(value) {
            if (value === password) {
              return true;
            } else {
              return "The passwords do not match";
            }
          }
        })} type="password" placeholder="Confirm Password" className="w-full"/>
        <ErrorMessage
          errors={errors}
          name="confirmPassword"
          render={({ messages }) => {
            return messages
              ? Object.entries(messages).map(([type, message]) => (
                  <p key={type}>{message}</p>
                ))
              : null;
          }}
        />

        <button type="submit" className="w-full">Signup</button>
      </form>
    </div>
  )
}

export default Signup