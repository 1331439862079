import React from 'react';

const RegularContainer = ({ children }) => {
  return (
    <div className="p-10">
      {children}
    </div>
  );
};

export default RegularContainer;