import { useUser } from "../contexts/UserContext";
import VCenteredContainer from '../components/containers/VCenteredContainer';

const Home = () => {  
  const { user } = useUser();

  return (
    <VCenteredContainer>
      <div className={'titleContainer'}>
        <h1 className='text-5xl font-bold'>Olá {user?.first_name ? user.first_name : ''}! </h1>
      </div>
      <div className={'buttonContainer'}>
        {
          user?.email ? (
            <>
              <div>O seu email é {user.email}</div>
            </>
          ) : null
        }
      </div>
    </VCenteredContainer>
  )
}

export default Home