import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { apiInstance } from "../services/api_service";
import { toast } from 'react-toastify';
import { useUser } from "../contexts/UserContext";
import RegularContainer from '../components/containers/RegularContainer';

const Profile = () => {
  const { user, fetchUser } = useUser();
  const { register, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
    },
    criteriaMode: "all"
  });

  const onSubmit = async (formData) => {
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
    };

    const response = await apiInstance.put(
      'authentication/user/data',
      payload, {headers: {'Content-Type': 'application/json'}}
    ).catch((error) => {
      if (error.status === 400) {
        const { data } = error.response;
        let delay = 0;
        for (const key in data) {
          const value = data[key];
          toast.error(<div>{`${value.toLowerCase()}`}</div>, {
            position: "top-right",
            delay: delay
          });
          delay += 500;
        }
      }
    });

    if (response === undefined) {
      return;
    }
    
    toast.success("Perfil atualizado com sucesso", {
      position: "top-right",
      autoClose: 800,
    });
    await fetchUser();
  }

  return (
    <RegularContainer>
      <form onSubmit={handleSubmit(onSubmit)} class="">
        <div class="flex gap-x-6 mb-6">
          <div class="w-full relative">
            <label class="flex  items-center mb-2 text-gray-600 text-sm font-medium">Primeiro nome<svg width="7" height="7" class="ml-1" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444" />
            </svg>
            </label>
            <input 
              {...register("firstName", {
                required: "Campo obrigatório",
                // pattern: {
                //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                //   message: "Invalid email address"
                // }
              })}
              type="text" 
              class="block w-full h-11 px-5 py-2.5 bg-white leading-7 text-base font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none" 
            />
            <ErrorMessage
              errors={errors}
              name="firstName"
              render={({ messages }) => {
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                    <label key={type} className="errorLabel">{message}</label>
                    ))
                  : null;
              }}
            />
          </div>
          <div class="w-full relative">
            <label class="flex  items-center mb-2 text-gray-600 text-sm font-medium">Sobrenome<svg width="7" height="7" class="ml-1" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444" />
            </svg>
            </label>
            <input
              {...register("lastName", {
                required: "Campo obrigatório",
                // pattern: {
                //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                //   message: "Invalid email address"
                // }
              })}
              type="text" 
              class="block w-full h-11 px-5 py-2.5 bg-white leading-7 text-base font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none" 
            />
            <ErrorMessage
              errors={errors}
              name="lastName"
              render={({ messages }) => {
                return messages
                  ? Object.entries(messages).map(([type, message]) => (
                    <label key={type} className="errorLabel">{message}</label>
                    ))
                  : null;
              }}
            />
          </div>
        </div>
        <div class="relative mb-6">
          <label class="flex  items-center mb-2 text-gray-600 text-sm font-medium">Email<svg width="7" height="7" class="ml-1" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.11222 6.04545L3.20668 3.94744L1.43679 5.08594L0.894886 4.14134L2.77415 3.18182L0.894886 2.2223L1.43679 1.2777L3.20668 2.41619L3.11222 0.318182H4.19105L4.09659 2.41619L5.86648 1.2777L6.40838 2.2223L4.52912 3.18182L6.40838 4.14134L5.86648 5.08594L4.09659 3.94744L4.19105 6.04545H3.11222Z" fill="#EF4444" />
          </svg>
          </label>
          <input type="text" id="default-search" value={user?.email} disabled class="block w-full h-11 px-5 py-2.5 bg-gray-200 leading-7 text-base font-normal shadow-xs text-gray-900 bg-transparent border border-gray-300 rounded-full placeholder-gray-400 focus:outline-none " placeholder="" required="" />
        </div>
        
        <button class="w-52 h-12 shadow-sm rounded-full bg-indigo-600 hover:bg-indigo-800 transition-all duration-700 text-white text-base font-semibold leading-7">Salvar</button>
      </form>
    </RegularContainer>
  );
}

export default Profile;