import { useEffect, useState } from "react";
import { authApiInstance } from "../services/api_service";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkAuth = async () => {
    try {
      const response = await authApiInstance.get("authentication/user/verify");
      setIsAuthenticated(response.status === 204);
    } catch {
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  return { isAuthenticated, loading, checkAuth };
};

export default useAuth;
