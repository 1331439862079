import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { apiInstance } from '../services/api_service';

const GOOGLE_SOCIAL_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_SOCIAL_AUTH_CLIENT_ID;

function GoogleLoginComponent() {

  const handleSuccess = async (credentialResponse) => {
    const tokenId = credentialResponse.credential;
    try {
      const payload = {access_token: tokenId};
      const response = await apiInstance.post(
        'authentication/user/google', 
        payload, 
        {headers: {'Content-Type': 'application/json'}}
      );
      console.log(`User info`, response);
      sessionStorage.clear();
      window.location.replace('/');
    } catch (err) {
      console.error('Failed to fetch user info');
      return
    } 
  };

  const handleFailure = (error) => {
      console.error("Google login failed", error);
  };

  return (
      <GoogleOAuthProvider clientId={GOOGLE_SOCIAL_AUTH_CLIENT_ID}>
          <GoogleLogin
              onSuccess={handleSuccess}
              onError={handleFailure}
          />
      </GoogleOAuthProvider>
  );
}

export default GoogleLoginComponent;
