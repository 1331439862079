import React from 'react';

const VCenteredContainer = ({ children }) => {
  return (
    <div className="pt-40 overflow-auto h-full relative px-12 pb-12">
      {children}
    </div>
  );
};

export default VCenteredContainer;